
app-survey-creation-design {
    /* Estilos específicos para app-survey-creation-design */
    width: 100%;
}

app-dashboard {
    width: 83%;
}

.mat-icon {
    overflow: visible !important;
}

@import "./styles/theme";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.mat-mdc-icon-button.mat-mdc-button-base {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-delete-icon {
    padding-top: 2.5px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: "10";
    font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12";
    font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14";
    font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14";
    font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16";
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18";
    font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
    content: "20";
    font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: "22";
    font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24";
    font-size: 24px !important;
}

.ql-editor li {
    position: relative;
}

.card-list {
    ol li {
        list-style-type: unset !important;
        padding-left: 0.2em !important;
    }
    ul li {
        list-style-type: unset !important;
        padding-left: 0.2em !important;
    }
}

.quill-editor {
    .ql-container {
        .ql-editor {
            ol {
                padding-left: 0 !important;
            }
            ul {
                padding-left: 0 !important;
            }
        }
    }
}

.mat-mdc-tooltip .mdc-tooltip__surface {
    font-size: 18px !important;
    padding: 8px;
}

.comment-box {
    .mdc-text-field--outlined .mdc-notched-outline {
        padding: 0px 0px 0 15px;
    }
}
.comment-box {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
        padding: 0px 0px 0 15px;

    }
}
